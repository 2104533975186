var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.props.row["ram4mAssessImprIds"] && _vm.props.row["stepFlagNames"]
        ? _vm._l(
            _vm.$comm.fmImprTagItems(_vm.props.row),
            function (item, index) {
              return _c(
                "q-chip",
                {
                  key: index,
                  staticStyle: { "margin-bottom": "4px !important" },
                  attrs: {
                    outline: "",
                    square: "",
                    color: item.color,
                    clickable: true,
                    "text-color": "white",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.openImprPop(item, _vm.props.row)
                    },
                  },
                },
                [
                  _vm._v(" " + _vm._s(item.title) + " "),
                  _c("c-assess-tooltip", {
                    attrs: { isVendor: "", ibmTooltip: item.ibmFmTooltip },
                  }),
                ],
                1
              )
            }
          )
        : void 0,
      _vm.editable &&
      Boolean(_vm.props.row.ram4mAssessScenarioId) &&
      _vm.props.row.editFlag !== "C" &&
      _vm.inputEditable
        ? _c(
            "div",
            [
              _c(
                "q-btn-group",
                { staticClass: "ColumInnerBtnGroup", attrs: { outline: "" } },
                [
                  _c(
                    "q-btn",
                    {
                      staticClass: "ColumInnerBtn",
                      attrs: {
                        icon: "add",
                        color: "red-6",
                        "text-color": "white",
                        align: "center",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.innerBtnClicked(_vm.props)
                        },
                      },
                    },
                    [_c("q-tooltip", [_vm._v(" 개선등록 ")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }